<template>
  <div>
    <p class="mb-50 title-style">
      TRAZABILIDAD
    </p>
    <b-table
      responsive
      :items="traceabilities"
      :fields="columns"
    >
      <template #cell(machine)="data">
        <span v-if="data.item.machine">
          {{ data.item.machine.name }}
        </span>
        <span v-else-if="data.item.maquina">
          {{ data.item.maquina }}
        </span>
      </template>
      <template #cell(id)="data">
        {{ (data.index+1) }}
      </template>
      <template #cell(actions)="data">
        <a
            class="d-inline-block px-50"
            @click="showModal(data.item)"
        >
          <feather-icon icon="Edit2Icon" />
        </a>
      </template>
    </b-table>
    <b-modal
      v-model="modalShow"
      :title="'Editar trazabilidad'"
      size="lg"
      hide-footer
    >
      <form-edit-trace :traceability="edit" />
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FormEditTrace from '@/views/traceabilities/FormEditTrace.vue'

export default {
  components: {
    FormEditTrace,
    BTable,
  },
  directives: {
    Ripple,
  },
  props: ['traceabilities', 'modalClose'],
  data() {
    return {
      edit: {},
      modalShow: false,
      items: [],
      columns: [
        {
          label: '',
          key: 'id',
        },
        {
          label: 'Máquina',
          key: 'machine',
        },
        {
          label: 'Orden de fabricación',
          key: 'of',
        },
        {
          label: 'Cantidad',
          key: 'cantidad',
        },
        {
          label: 'Perfil',
          key: 'perfil',
        },
        {
          label: 'Nº Colada',
          key: 'colada',
        },
        {
          label: 'Marca',
          key: 'marca',
        },
        {
          label: 'Acciones',
          key: 'actions',
          class: 'text-right',
          thStyle: { 'min-width': '160px' },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      chargeList: 'traceabilities/getChargeList',
    }),
  },
  watch: {
    chargeList() {
      if (this.chargeList === 1) {
        this.modalShow = false
        this.setChargeList(0)
      }
    },
  },
  methods: {
    ...mapActions({
      getSelectMachines: 'machines/getSelectMachines',
    }),
    ...mapMutations({
      setChargeList: 'traceabilities/setChargeList',
    }),
    showModal(item) {
      this.edit = item
      this.modalShow = true
    },
  },
  async created() {
    this.getSelectMachines()
  },
}
</script>
