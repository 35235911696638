<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0">
        <span @click="$router.go(-1)">
          <b-button
            variant="white"
            class="btn-icon rounded-circle mr-50 text-danger bg-white"
            size="sm"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span>
        Información de la tarea
      </h2>
    </div>
    <b-row v-if="taskPrepare">
      <b-col cols="12">
        <b-card class="height-card">
          <b-row class="position-relative">
            <b-col
              cols="3"
              class="mb-2"
            >
              <p class="mb-50 title-style">
                CLIENTE
              </p>
              <p
                v-if="task.client_object"
                class="font-weight-bolder"
              >
                <b-link
                  :to="{
                    name: 'viewClient',
                    params: { id: task.client_object.id },
                  }"
                  class="link"
                >{{ task.client }}</b-link>
              </p>
            </b-col>
            <b-col
              cols="3"
              class="mb-2"
            >
              <p class="mb-50 title-style">
                NOMBRE DE LA OBRA
              </p>
              <p
                v-if="task.obra"
                class="font-weight-bolder"
              >
                <b-link
                  :to="{ name: 'viewProject', params: { id: task.obra.id } }"
                  class="link"
                >{{ task.obra.name }}</b-link>
              </p>
            </b-col>
            <b-col
              cols="3"
              class="mb-2"
            >
              <p class="mb-50 title-style">
                TRABAJO
              </p>
              <p
                v-if="task.task"
                class="font-weight-bolder"
              >
                <b-link
                  :to="{ name: 'viewJob', params: { id: task.task.id } }"
                  class="link"
                >{{ task.task.name }}</b-link>
              </p>
            </b-col>
            <!--   <b-col cols="3" class="mb-2" >
              <p class="mb-50 title-style" >SKU</p>
              <p class="font-weight-bolder" >{{ task.SKU }}</p>
            </b-col> -->
            <b-col
              cols="3"
              class="mb-2"
            >
              <p class="mb-50 title-style">
                HORAS IMPUTADAS
              </p>
              <p class="font-weight-bolder">
                {{ task.total_hours }}
              </p>
            </b-col>
            <b-col
              cols="3"
              class="mb-2"
            >
              <p class="mb-50 title-style">
                ESTADO DE LA TAREA
              </p>
              <div>
                <b-badge :variant="statusTasksVariants[task.status]">
                  {{ statusTasksText[task.status] }}
                </b-badge>
              </div>
            </b-col>
            <b-col
              cols="3"
              class="mb-2"
            >
              <p class="mb-50 title-style">
                FECHA
              </p>
              <p class="font-weight-bolder">
                {{ TimestampToFlatPickr(task.day) }}
              </p>
            </b-col>
            <b-col
              cols="3"
              class="mb-2"
            >
              <p class="mb-50 title-style">
                USUARIO
              </p>
              <b-link
                :to="{ name: 'viewUser', params: { id: task.user.id } }"
                class="link"
              >{{ task.user.name }}</b-link>
            </b-col>
            <b-col
              sm="12"
              class="mb-2"
            >
              <p class="mb-50 title-style">
                DESCRIPCIÓN
              </p>
              <p class="font-weight-bolder">
                {{ stripHtml(task.description) }}
              </p>
            </b-col>
            <b-col
              v-if="task.documents.length"
              cols="12"
              class="mb-2"
            >
              <p class="mb-50 title-style">
                DOCUMENTOS
              </p>
              <div>
                <div
                  v-for="(d, i) in task.documents"
                  class="d-inline-block link-document"
                >
                  <b-link
                    :href="d.path"
                    download
                    target="_blank"
                  >
                    <div class="mb-50">
                      <img :src="fileIcon(d.type)">
                    </div>
                    Archivo {{ i + 1 }}
                  </b-link>
                </div>
              </div>
            </b-col>
            <b-col
              v-if="task.comments"
              cols="12"
              class="mb-2"
            >
              <p class="mb-50 title-style">
                COMENTARIO
              </p>
              <b-row>
                <b-col cols="6">
                  <div class="comment">
                    <div class="comment-img pr-1">
                      <b-avatar :src="task.user.avatar_url" />
                    </div>
                    <div class="comment-text">
                      <p class="m-0">
                        {{ task.comments }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              v-if="task.images.length"
              cols="12"
              class="mb-2"
            >
              <p class="mb-50 title-style">
                DOCUMENTOS OPERARIO
              </p>
              <div>
                <div
                  v-for="(d, i) in task.images"
                  class="d-inline-block link-document"
                >
                  <b-link
                    :href="d.path"
                    download
                    target="_blank"
                  >
                    <div class="mb-50">
                      <img :src="fileIcon(d.type)">
                    </div>
                    Archivo {{ i + 1 }}
                  </b-link>
                </div>
              </div>
            </b-col>
            <b-col
              v-if="task.incidence"
              cols="12"
              class="mb-2"
            >
              <p class="mb-50 title-style">
                INCIDENCIA
              </p>
              <p class="font-weight-bolder">
                {{ task.incidence_comment }}
              </p>
              <div v-if="task.incidence_documents.length">
                <div
                  v-for="(d, i) in task.incidence_documents"
                  class="d-inline-block link-document"
                >
                  <b-link
                    :href="d.path"
                    download
                    target="_blank"
                  >
                    <div class="mb-50">
                      <img :src="fileIcon(d.type)">
                    </div>
                    Archivo incidencia {{ i + 1 }}
                  </b-link>
                </div>
              </div>
            </b-col>
            <b-col
              v-if="task.traceabilities"
              md="12"
              class="mb-2"
            >
              <TableTraceability :traceabilities="task.traceabilities"></TableTraceability>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {
  BBadge,
  BRow,
  BCol,
  BCard,
  BAvatar,
  BLink,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { TimestampToFlatPickr } from '@/libs/helpers'
import { config } from '@/shared/app.config'
import TableTraceability from '@/components/traceability/TableTraceability.vue'

export default {
  components: {
    TableTraceability,
    BButton,
    BBadge,
    BLink,
    BRow,
    BCol,
    BCard,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      taskPrepare: false,
      closeModal: false,
      statusTasksVariants: config.statusTasksVariants,
      statusTasksText: config.statusTasksText,
      iconText: require('@/assets/images/files/text.png'),
      iconImage: require('@/assets/images/files/image.png'),
    }
  },
  computed: {
    ...mapGetters({
      task: 'tasks/getTask',
      chargeList: 'traceabilities/getChargeList',
    }),
  },
  watch: {
    chargeList() {
      if (this.chargeList === 1) {
        this.getTask(this.$route.params.id)
      }
    },
  },
  methods: {
    ...mapActions({
      getTask: 'tasks/getTask',
    }),
    ...mapMutations({
      loading: 'app/loading',
      setChargeList: 'traceabilities/setChargeList',
    }),
    TimestampToFlatPickr(f) {
      return TimestampToFlatPickr(f)
    },
    fileIcon(type) {
      switch (type) {
        case 'img':
          return this.iconImage
        default:
          return this.iconText
      }
    },
    stripHtml(html) {
      const tmp = document.createElement('DIV')
      tmp.innerHTML = html
      return tmp.textContent || tmp.innerText || ''
    },
  },
  async created() {
    await this.getTask(this.$route.params.id)
    this.taskPrepare = true
  },
}
</script>
